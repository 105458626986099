// extracted by mini-css-extract-plugin
export var item = "ExperienceItem-module--item--2rGjX";
export var timeline = "ExperienceItem-module--timeline--37L3C";
export var timeline__line = "ExperienceItem-module--timeline__line--28F6L";
export var timeline__dot = "ExperienceItem-module--timeline__dot--2y2Ti";
export var content = "ExperienceItem-module--content--1kxzW";
export var content__header = "ExperienceItem-module--content__header--1jIwb";
export var content__technologies = "ExperienceItem-module--content__technologies--2cQaU";
export var content__period = "ExperienceItem-module--content__period--2wq8d";
export var content__title = "ExperienceItem-module--content__title--18Xqe";
export var content__company = "ExperienceItem-module--content__company--CYjc8";
export var content__technology = "ExperienceItem-module--content__technology--2VwCW";
export var content__description = "ExperienceItem-module--content__description--3md_Y";