import React from 'react';
import { Helmet } from 'react-helmet';
import { DefaultLayout } from '../layouts/default'
import { Contact } from '../components/shared/contact/Contact';
import { Experience, Achievements, Knowledge } from '../components/home';
import * as classes from './index.module.scss';

const About: React.FC = () => {
    return (
        <DefaultLayout>
            <Helmet>
                <title>Bravecode | Digital CV</title>
                <meta  name="description" content="Portfolio of Krzysztof Szymański known as bravecode. Explore Digital CV and detailed project descriptions. Let's work together!" />
            </Helmet>
            
            <main className={classes['page']}>
                
                <Experience />

                <Achievements />

                <Knowledge />

                <Contact />
            
            </main>
        </DefaultLayout>
    );
}

export default About;