import React from 'react';
import { Tag } from '../../shared/tag/Tag';
import * as classes from './ExperienceItem.module.scss';

export interface ExperienceItemProps {
    period: string;
    title: string;
    company: string;
    technologies: string[];
    description: string;
}

const ExperienceItem: React.FC<ExperienceItemProps> = ({
    period,
    title,
    company,
    technologies,
    description
}) => {
    return (
        <article className={classes['item']}>

            <div className={classes['timeline']}>
                <div className={classes['timeline__line']}>
                    <div className={classes['timeline__dot']} />
                </div>
            </div>

            <div className={classes['content']}>
                <header className={classes['content__header']}>
                    <h5 className={classes['content__period']}>
                        { period }
                    </h5>
                    <h4 className={classes['content__title']}>
                        { title } <span className={classes['content__company']}>@ { company }</span>
                    </h4>
                </header>

                <ul className={classes['content__technologies']}>
                    {
                        technologies.map((item) =>
                            <Tag key={item} className={classes['content__technology']} label={item} />
                        )
                    }
                </ul>

                <p className={classes['content__description']}>
                    { description }
                </p>
            </div>

        </article>
    );
}

export { ExperienceItem }