import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import * as classes from './ButtonAnchor.module.scss';

export interface ButtonAnchorProps {
    label: string;
    path: string;
    className?: string;
    external?: boolean;
}

const ButtonAnchor: React.FC<ButtonAnchorProps> = ({
    label,
    path,
    className,
    external
}) => {
    const containerStyles = classNames({
        [classes['button']]: true,
        [className]: className
    });

    if (external) {
        <a href={path} className={containerStyles}>
            <div className={classes['button__label']}>
                { label }<span>.</span>
            </div>
            <div className={classes['button__hover']} />
        </a>
    }

    return (
        <Link to={path} className={containerStyles}>
            <div className={classes['button__label']}>
                { label }<span>.</span>
            </div>
            <div className={classes['button__hover']} />
        </Link>
    );
}

export { ButtonAnchor }