import React from 'react';
import * as classes from './Contact.module.scss';

// Components
import { ButtonAnchor } from '../ui/button/ButtonAnchor';

const Contact: React.FC = () => {
    return (
        <section className={classes['contact']}>
            
            <div className={classes['contact__content']}>

                <header className={classes['contact__header']}>
                    <h3 className={classes['contact__title']}>
                        Let's work together<span>.</span>
                    </h3>
                    <p className={classes['contact__description']}>
                        & make your idea come to live.
                    </p>
                </header>

                <ButtonAnchor 
                    label="Contact"
                    path="/contact"
                    className={classes['contact__button']}
                />
            </div>

        </section>
    );
}

export { Contact }