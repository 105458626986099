import React from 'react';
import { SectionHeader } from '../../shared/section-header/SectionHeader';
import { ExperienceItem } from './ExperienceItem';
import * as classes from './Experience.module.scss';

const Experience: React.FC = () => {
    return (
        <section className={classes['experience']}>

            <SectionHeader 
                title="Experience"
                description="Find out that I'm perfect match for your company & swipe right on my profile."
            />

            <ExperienceItem 
                period="09.2019"
                title="Experienced Software Engineer"
                company="GFT"
                technologies={['React', 'GraphQL', 'TypeScript', 'Unit Tests', 'Cypress']}
                description="During my work here I was involved in multiple international projects in the banking section with the use of the most recent technologies such as React, GraphQL, Typescript. Apart from coding, one of my responsibilities was to care about code quality by doing code reviews, maintaining unit & integration tests. I also have improved my SCRUM knowledge (at one point I even took some responsibilities from my team leader during his absence). In 2020 I acquired a Google Cloud Associate Engineer certificate."
            />

            <ExperienceItem 
                period="10.2018 - 04.2019"
                title="Web Developer"
                company="Rapida"
                technologies={['React', 'SCSS (BEM)', 'A/B Split Tests', 'Customer.io Campaigns']}
                description="Working at Rapida was a great time to work on my self-organization and get comfortable with remote working. I had a chance to take part in the marketing part of the business by creating A/B split tests and managing e-mail & SMS campaigns with customer.io software."
            />

            <ExperienceItem 
                period="05.2018 - 10.2018"
                title="Web Developer"
                company="Rossmann"
                technologies={['JavaScript (jQuery)', 'Canvas', '.NET', 'Bootstrap']}
                description="As one of the first commercial tasks I have ever received was to create a canvas application that's goal was to visualize shelves, promotional stands & monthly changes for them. Since I was the only front-end developer assigned to this task it was a good challenge that required not only good coding knowledge but also communication with the rest of the team. How it turned out? The application was deployed to all stores in Poland in the first 3 weeks after the first release."
            />

        </section>
    );
}

export { Experience }