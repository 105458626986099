import { faClock, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Tag } from '../../shared/tag/Tag';
import * as classes from './KnowledgeTask.module.scss';

export interface KnowledgeTaskProps {
    title: string;
    category: string;
    date: string;
}

const KnowledgeTask: React.FC<KnowledgeTaskProps> = ({
    title,
    category,
    date
}) => {
    return (
        <div className={classes['task']}>
            <header className={classes['task__header']}>
                <Tag label={category} />
                <h5 className={classes['task__title']}>
                    { title }
                </h5>
            </header>
            <footer className={classes['task__footer']}>
                <div className={classes['task__author']}>
                    <FontAwesomeIcon icon={faUser} /> 
                    <span>Krzysztof Szymański</span>
                </div>
                <div className={classes['task__date']}>
                    <FontAwesomeIcon icon={faClock} />
                    <span>{ date }</span>
                </div>
            </footer>
        </div>
    );
}

export { KnowledgeTask }