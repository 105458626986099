import React from 'react';
import classNames from 'classnames';
import { Tag } from '../../shared/tag/Tag';
import * as classes from './KnowledgeCategory.module.scss';

export interface KnowledgeCategoryProps {
    title: string;
    level: 'MASTER' | 'ADVANCED' | 'INTERMEDIATE' | 'BEGINNER';
    technologies: string[];
}

const KnowledgeCategory: React.FC<KnowledgeCategoryProps> = ({
    title,
    level,
    technologies
}) => {
    const progressStyles = classNames({
        [classes['category__progress']]: true,
        [classes['category__progressMaster']]: level === 'MASTER',
        [classes['category__progressAdvanced']]: level === 'ADVANCED',
        [classes['category__progressIntermediate']]: level === 'INTERMEDIATE',
        [classes['category__progressBeginner']]: level === 'BEGINNER',
    });

    return (
        <div>

            <header className={classes['category__header']}>
                <span className={classes['category__title']}>
                    {title}
                </span>
                <span className={classes['category__level']}>
                    {level}
                </span>
            </header>

            <div className={progressStyles} />

            <div className={classes['category__technologies']}>
                {
                    technologies.map((item) =>
                        <Tag key={item} label={item} />
                    )
                }
            </div>

        </div>
    );
}

export { KnowledgeCategory }