import React from 'react';
import { SectionHeader } from '../../shared/section-header/SectionHeader';
import { KnowledgeBoard } from './KnowledgeBoard';
import { KnowledgeCategory } from './KnowledgeCategory';
import * as classes from './Knowledge.module.scss';

const Knowledge: React.FC = () => {
    return (
        <section className={classes['knowledge']}>

            <SectionHeader 
                title="Knowledge"
                description="Check out what's next in my learning pipeline and what I have already mastered."
            />

            <div className={classes['knowledge__categories']}>

                <KnowledgeCategory 
                    title="HTML & CSS"
                    level="MASTER"
                    technologies={['BEM', 'SCSS / SASS', 'Bootstrap']}
                />

                <KnowledgeCategory 
                    title="Javascript"
                    level="ADVANCED"
                    technologies={['React (Redux)', 'jQuery', 'Svelte']}
                />

                <KnowledgeCategory 
                    title="BACK END"
                    level="BEGINNER"
                    technologies={['Node (Adonis, Nest)', 'PHP (Laravel, Wordpress)', '.NET']}
                />

                <KnowledgeCategory 
                    title="OTHER"
                    level="INTERMEDIATE"
                    technologies={['GIT', 'Task Management', 'Adobe XD / Photoshop']}
                />

            </div>

            <div className={classes['knowledge__boards']}>

                <KnowledgeBoard 
                    title="TO DO"
                    items={[
                        {
                            title: 'Improve on Node / Deno',
                            category: 'BACK',
                            date: '01.06.2021'
                        }
                    ]}
                />

                <KnowledgeBoard 
                    title="IN PROGRESS"
                    items={[
                        {
                            title: 'Secret Project (Canvas / React)',
                            category: 'FRONT',
                            date: '01.06.2021'
                        }
                    ]}
                />

                <KnowledgeBoard 
                    title="DONE"
                    items={[
                        {
                            title: 'Portfolio Website (GatsbyJS)',
                            category: 'FRONT',
                            date: '01.06.2021'
                        },
                        {
                            title: 'Learn Svelte / Sapper Basics',
                            category: 'FRONT',
                            date: '01.06.2021'
                        }
                    ]}
                />

            </div>

        </section>
    );
}

export { Knowledge }