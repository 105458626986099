import React from 'react';
import { Achievement } from './Achievement';
import * as classes from './Achievements.module.scss';

const Achievements: React.FC = () => {
    return (
        <section className={classes['achievements']}>

            <Achievement 
                title="Weird, It works on my PC"
                description="Complete 8 commercial projects."
                date="15 June 2021"
            />

            <Achievement 
                title="Eat, Sleep, Code, Repeat"
                description="Acquire  'Google Cloud - Associate Cloud Engineer' certificate."
                date="15 June 2021"
            />

            <Achievement 
                title="Everything is under CTRL"
                description="Gather 3 years of commercial experience."
                date="15 June 2021"
            />

            <Achievement 
                title="The Codefather"
                description="Learn more than 5 programming languages & frameworks."
                date="15 June 2021"
            />

            <Achievement 
                title="It's not a bug, it's a feature"
                description="Learn bascis of UI / UX."
                date="15 June 2021"
            />

            <Achievement 
                title="Do you even code, bro?"
                description="Drink over 9000 cups of coffee."
                date="15 June 2021"
            />

        </section>
    );
}

export { Achievements }