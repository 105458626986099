import React from 'react';
import * as classes from './Achievement.module.scss';

export interface AchievementProps {
    title: string;
    description: string;
    date: string;
}

const Achievement: React.FC<AchievementProps> = ({
    title,
    description,
    date
}) => {
    return (
        <article className={classes['achievement']}>

            <header className={classes['achievement__header']}>
                <h4 className={classes['achievement__title']}>
                    { title }
                </h4>
                <p className={classes['achievement__description']}>
                    { description }
                </p>
            </header>

            <footer className={classes['achievement__footer']}>                
                <div className={classes['achievement__date']}>
                    { date }
                </div>
                <div className={classes['achievement__status']}>
                    Unlocked
                </div>
            </footer>

        </article>
    );
}

export { Achievement }