import React from 'react';
import { KnowledgeTask, KnowledgeTaskProps } from './KnowledgeTask';
import * as classes from './KnowledgeBoard.module.scss';

export interface KnowledgeBoardProps {
    title: string;
    items: KnowledgeTaskProps[];
}

const KnowledgeBoard: React.FC<KnowledgeBoardProps> = ({
    title,
    items
}) => {
    return (
        <div className={classes['board']}>

            <header className={classes['board__header']}>
                <h4 className={classes['board__title']}>
                    { title }
                </h4>
            </header>

            <div>
                {
                    items.map((item) =>
                        <KnowledgeTask key={item.title} {...item} />
                    )
                }
            </div>

        </div>
    );
}

export { KnowledgeBoard }